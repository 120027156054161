import type { Role, User } from "@/types/user";

enum Action {
	"view" = 1,
	"edit" = 33,
	"create" = 66,
	"delete" = 99,
}

export const useUserStore = defineStore("user", () => {
	const { $sentry } = useNuxtApp();
	const user = ref<User | null>(null);
	const isTengivaUser = ref(false);
	const isDeveloper = ref(false);
	const gitHubToken = ref("");
	const { getRolesUser, viewByFieldAndPlatformUsersData } = useURMApi();
	const orgStore = useOrgStore();

	watchEffect(() => {
		if (user.value?.permissions && user.value?.organization_id) {
			// @ts-ignore
			const permissions = user?.value.permissions;
			const org_id = user.value?.organization_id;
			if (permissions && org_id && permissions[org_id]) {
				isDeveloper.value = Object.keys(permissions[org_id]).includes("Development");
			}

			if (isDeveloper.value) {
				initGitHubToken();
			}
		}
	});

	watchEffect(async () => {
		if (import.meta.server) return;
		if (orgStore.currentOrg?.id && user.value) {
			try {
				const { response } = await getRolesUser(user.value.id, orgStore.currentOrg?.id);
				user.value.roles = (response?.data as Role[]) || [];
				user.value.superAdmin = user.value.roles.some(role => role.name === "Admin" || role.name === "Super Admin");
			} catch (err: any) {
				$sentry?.captureException(`Can not get user roles,  user id: ${user.value.id}. ${err.toString()}`);
			}
		}
	});

	watchEffect(() => {
		if (user.value?.organization_id === "bf1da613-4290-48cb-b170-1dd595218fdb") {
			isTengivaUser.value = true;
		}
	});

	const initGitHubToken = async () => {
		try {
			const { response } = await viewByFieldAndPlatformUsersData("github_token", "Cacith");
			if (response?.data) {
				gitHubToken.value = (response?.data as any)?.value as string;
				return true;
			}
		} catch (err: any) {
			$sentry?.captureException(`Can not get user GitHub Token, user id: ${user?.value?.id}. ${err.toString()}`);
		}
		return false;
	};

	const setUser = async (newUser: User | null) => {
		if (newUser) {
			user.value = {
				...newUser,
				roles: [],
				superAdmin: false,
			};
			await orgStore.updateOrgs(newUser.organization_id);
		}
	};

	const iCan = (action: keyof typeof Action, orgId: string) => {
		const permissions = user.value?.permissions || {};
		const permissionsInTheOrg = permissions[orgId];
		if (permissionsInTheOrg) {
			const roles = Object.keys(permissionsInTheOrg);
			return roles.some(role => permissionsInTheOrg[role] >= Action[action]);
		}
		return false;
	};

	return {
		user,
		isDeveloper,
		isTengivaUser,
		gitHubToken,
		initGitHubToken,
		iCan,
		setUser,
	};
});
