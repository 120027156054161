import { default as indexA70msfxgRNMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/account-activation/index.vue?macro=true";
import { default as _91service_93ubXO9wB2EnMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/api/[service].vue?macro=true";
import { default as indexBOhlkVRgfXMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/api/index.vue?macro=true";
import { default as authIttvxbC3YjMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/auth.vue?macro=true";
import { default as studiofybHbnk5kCZGMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/authorize/studiofy.vue?macro=true";
import { default as change_45passwordMCV67CarAbMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/change-password.vue?macro=true";
import { default as pull_requestss41x1zQ4X6Meta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/dev/pull_requests.vue?macro=true";
import { default as test_users6ia5FYQaNnMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/dev/test_users.vue?macro=true";
import { default as forgot_45password2mnkOiiiXcMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/forgot-password.vue?macro=true";
import { default as indexLU1bLsUm9PMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/index.vue?macro=true";
import { default as _91slug_93PYEzpEZwPKMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/kiosk/[slug].vue?macro=true";
import { default as indexqNprTh1HkyMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/kiosk/index.vue?macro=true";
import { default as sign_45inYfWQpAhLBgMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/sign-in.vue?macro=true";
import { default as clientshyXZHMOn8mMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/clients.vue?macro=true";
import { default as covolt_45access8IOwJ1V6YUMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/covolt-access.vue?macro=true";
import { default as indexieLOFKrRZAMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-delivery/index.vue?macro=true";
import { default as digitization_45queuepqcHMI6H5aMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-queue.vue?macro=true";
import { default as index7zpv1OEcLIMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-request/index.vue?macro=true";
import { default as digitization_45requestsQINbKdU2tkMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-requests.vue?macro=true";
import { default as digitization_45skustXL2KlIU5cMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-skus.vue?macro=true";
import { default as _91id_933941K1icZCMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-swatches-storage/[id].vue?macro=true";
import { default as digitization_45swatchesKlzSmW3ANyMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-swatches.vue?macro=true";
import { default as digitization_45workflow96jTjw2397Meta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-workflow.vue?macro=true";
import { default as my_45teamJWtkG6Gdc6Meta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/my-team.vue?macro=true";
import { default as _91id_9362VwsIoOIYMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/organization/[id].vue?macro=true";
import { default as organizationskTz1wxd3XsMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/organizations.vue?macro=true";
import { default as packagesDOEu5Y0PsWMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/packages.vue?macro=true";
import { default as _91id_93nfqM9104iOMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/products/[id].vue?macro=true";
import { default as index5fN3jHrSb1Meta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/sample-orders/index.vue?macro=true";
import { default as service_45subscriptions4N9WQc5qajMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/service-subscriptions.vue?macro=true";
import { default as servicesgxCbwNXgmFMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/services.vue?macro=true";
import { default as translation_45managementevuNZVORtWMeta } from "/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/translation-management.vue?macro=true";
export default [
  {
    name: "account-activation",
    path: "/account-activation",
    meta: indexA70msfxgRNMeta || {},
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/account-activation/index.vue")
  },
  {
    name: "api-service",
    path: "/api/:service()",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/api/[service].vue")
  },
  {
    name: "api",
    path: "/api",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/api/index.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: authIttvxbC3YjMeta || {},
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/auth.vue")
  },
  {
    name: "authorize-studiofy",
    path: "/authorize/studiofy",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/authorize/studiofy.vue")
  },
  {
    name: "change-password",
    path: "/change-password",
    meta: change_45passwordMCV67CarAbMeta || {},
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/change-password.vue")
  },
  {
    name: "dev-pull_requests",
    path: "/dev/pull_requests",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/dev/pull_requests.vue")
  },
  {
    name: "dev-test_users",
    path: "/dev/test_users",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/dev/test_users.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password2mnkOiiiXcMeta || {},
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/index.vue")
  },
  {
    name: "kiosk-slug",
    path: "/kiosk/:slug()",
    meta: _91slug_93PYEzpEZwPKMeta || {},
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/kiosk/[slug].vue")
  },
  {
    name: "kiosk",
    path: "/kiosk",
    meta: indexqNprTh1HkyMeta || {},
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/kiosk/index.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45inYfWQpAhLBgMeta || {},
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/sign-in.vue")
  },
  {
    name: "tengiva-admin-clients",
    path: "/tengiva-admin/clients",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/clients.vue")
  },
  {
    name: "tengiva-admin-covolt-access",
    path: "/tengiva-admin/covolt-access",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/covolt-access.vue")
  },
  {
    name: "tengiva-admin-digitization-delivery",
    path: "/tengiva-admin/digitization-delivery",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-delivery/index.vue")
  },
  {
    name: "tengiva-admin-digitization-queue",
    path: "/tengiva-admin/digitization-queue",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-queue.vue")
  },
  {
    name: "tengiva-admin-digitization-request",
    path: "/tengiva-admin/digitization-request",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-request/index.vue")
  },
  {
    name: "tengiva-admin-digitization-requests",
    path: "/tengiva-admin/digitization-requests",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-requests.vue")
  },
  {
    name: "tengiva-admin-digitization-skus",
    path: "/tengiva-admin/digitization-skus",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-skus.vue")
  },
  {
    name: "tengiva-admin-digitization-swatches-storage-id",
    path: "/tengiva-admin/digitization-swatches-storage/:id()",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-swatches-storage/[id].vue")
  },
  {
    name: "tengiva-admin-digitization-swatches",
    path: "/tengiva-admin/digitization-swatches",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-swatches.vue")
  },
  {
    name: "tengiva-admin-digitization-workflow",
    path: "/tengiva-admin/digitization-workflow",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/digitization-workflow.vue")
  },
  {
    name: "tengiva-admin-my-team",
    path: "/tengiva-admin/my-team",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/my-team.vue")
  },
  {
    name: "tengiva-admin-organization-id",
    path: "/tengiva-admin/organization/:id()",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/organization/[id].vue")
  },
  {
    name: "tengiva-admin-organizations",
    path: "/tengiva-admin/organizations",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/organizations.vue")
  },
  {
    name: "tengiva-admin-packages",
    path: "/tengiva-admin/packages",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/packages.vue")
  },
  {
    name: "tengiva-admin-products-id",
    path: "/tengiva-admin/products/:id()",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/products/[id].vue")
  },
  {
    name: "tengiva-admin-sample-orders",
    path: "/tengiva-admin/sample-orders",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/sample-orders/index.vue")
  },
  {
    name: "tengiva-admin-service-subscriptions",
    path: "/tengiva-admin/service-subscriptions",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/service-subscriptions.vue")
  },
  {
    name: "tengiva-admin-services",
    path: "/tengiva-admin/services",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/services.vue")
  },
  {
    name: "tengiva-admin-translation-management",
    path: "/tengiva-admin/translation-management",
    component: () => import("/home/runner/work/Frontend-CACITH/Frontend-CACITH/pages/tengiva-admin/translation-management.vue")
  }
]